/**
 * Mixin
 */

$breakpoints: (
  'md': 'screen and (max-width: 768px)',
  'lg': 'screen and (max-width: 1024px)'
) !default;

@mixin bs-mq($breakpoint: md) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}

@mixin bs-button() {
  vertical-align: middle;
  cursor: pointer;
  margin: 10px;
  padding: 8px 40px;
  font-weight: bold;
  font-size: 16px;
  white-space: nowrap;
  line-height: 24px;
  font-family: "SF Pro JP", "SF Pro Text", "SF Pro Icons", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", "メイリオ", "Meiryo", "ＭＳ Ｐゴシック", "Helvetica Neue", "Helvetica", "Arial", "sans-serif";
  text-decoration: none !important;
  display: inline-block;
  text-align: center;
  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75);
  background-color: #f5f5f5;
  background-image: -ms-linear-gradient(top, #fff, #e6e6e6);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#fff), to(#e6e6e6));
  background-image: -webkit-linear-gradient(top, #fff, #e6e6e6);
  background-image: -o-linear-gradient(top, #fff, #e6e6e6);
  background-image: linear-gradient(top, #fff, #e6e6e6);
  background-image: -moz-linear-gradient(top, #fff, #e6e6e6);
  background-repeat: repeat-x;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);

  &:hover {
    color: #333;
    text-decoration: none;
    background-color: #e6e6e6;
    background-position: 0 -15px;
    transition: background-position .1s linear;
  }
  &:active {
    color: #333;
    border: 1px solid #CCC;
    text-decoration: none;
    background: #cdcdcd;
    background: -moz-linear-gradient(top, #cdcdcd, #eee 100%);
    background: -webkit-gradient(linear, left top, left bottom, from(#cdcdcd), color-stop(100%, #eee));
    background: -o-linear-gradient(top, #cdcdcd, #eee 100%);
  }
}
