/**
 * Footer
 */

/* Footer
----------------------------------------------- */

.bs-footer {
  background-color: #001800;
  padding: 80px 0;

  @include bs-mq() {
    padding: 80px 20px;
  }

  &__copyright {
    font-size: 10px;
    color: #999;
    width: 1260px;
    margin: 0 auto;
    @include bs-mq() {
      width: 100%;
      text-align: center;
      font-size: 12px;
      line-height: 1.8;
    }

  }

  &__banner {
    @include bs-mq() {
      display: block;
    }
  }

  &__banner-link {
    img {
      border: 0;
      vertical-align: text-bottom;
    }
  }

}


