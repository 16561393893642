/**
 * Top Page
 */

/* Main Image
----------------------------------------------- */
.bs-main-image {
  margin: 0 auto;
  padding: 0;
  display: none;
  z-index: 0;
}

.bx-wrapper {
  margin: 0 auto;
  max-height: 560px;
  overflow: hidden;
}

.bx-controls {
  display: none;
}


/* Info
----------------------------------------------- */
.bs-info {
  width: 100%;
  margin-bottom: 100px;

  h2 {
    font-weight: bold !important;
  }
  @include bs-mq() {
    margin-bottom: 100px;
  }
}

/* Top Post
----------------------------------------------- */

.bs-top-post {
  margin: 0 0 20px 0 !important;
  padding: 0 !important;

  &__item {
    border-bottom: 1px solid #ddd;
    margin: 0 !important;
    padding: 20px 0 20px !important;
    list-style: none;
    font-size: 14px;

    &::before {
      content: none !important;
    }

    a {
      transition: all 300ms;
      text-decoration: none;

      &:link,
      &:visited {
        color: #000 !important;
      }

      &:hover {
        color: #555 !important;
      }

    }

    &-eye-catch {
      transition: all 300ms;
      float: left;
      display: block;
      margin-right: 20px !important;

      &:hover {
        opacity: 0.7;
      }
    }

    &-date {
      margin: 0 !important;
      padding-right: 10px;
      font-size: 13px;
      letter-spacing: 0;
      color: #a0a2a8;
      font-style: italic;
      font-family: YakuHanJP, "Barlow", "Noto Sans JP", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
      @include bs-mq() {
        margin-bottom: 6px !important;;
      }
    }

    &-category {
      font-family: "SF Pro JP", "SF Pro Text", "SF Pro Icons", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", "メイリオ", "Meiryo", "ＭＳ Ｐゴシック", "Helvetica Neue", "Helvetica", "Arial", "sans-serif";
      font-size: 10px;
      border: 1px solid #000;
      padding: 2px 5px;
      border-radius: 10px;
      white-space: nowrap;
    }

    &-title {
      font-family: "SF Pro JP", "SF Pro Text", "SF Pro Icons", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", "メイリオ", "Meiryo", "ＭＳ Ｐゴシック", "Helvetica Neue", "Helvetica", "Arial", "sans-serif";
      display: block;
      padding: 10px 0;
      font-size: 16px;
      line-height: 1.6 !important;
      font-weight:bold;
    }

    &-detail {
      color: #a0a2a8;
    }
  }
}

/* Top Post To List
----------------------------------------------- */

.bs-top-post-to-list {
  text-align: right;

  @include bs-mq() {
    text-align: center;
  }

  a {
    transition: all 300ms;
    letter-spacing: 0.1em;
    font-weight: bold;
    padding: 8px 40px;
    font-size: 9px;
    background-color: #001800;
    color: #fff !important;
    text-decoration: none;
    @include bs-mq() {
      font-size: 12px;
      padding: 12px 60px;
    }

    &:link,
    &:visited {
      color: #fff;
    }

    &:hover {
      background-color: #555;
    }
  }
}

/* Bge Contents
----------------------------------------------- */

#Home .bge-contents {
  margin-bottom: 100px;
}
