/**
 * Main
 */

.bs-main-contents {
  float: left;
  width: 860px;
  margin: 49px 0 160px;
  padding: 0;

  @include bs-mq() {
    width: 100%;
  }
}

#Home .bs-main-contents {
  margin: 100px 0;

  @include bs-mq() {
    float: none;
    padding: 0;
    margin: 40px 0;
  }
}



